@import "src/styles/helpers/variables/palette";

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.slider.slider-horizontal {
  width: calc(100% - 50px);
  height: 20px;
  margin: 30px 0px 15px 10px;
}
.slider.slider-horizontal .slider-track {
  height: 3px;
  width: 100%;
  margin-top: -5px;
  top: 56%;
  left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
  margin-left: -10px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: #1d70b8;
  margin-top: 0;
}
.slider.slider-horizontal .slider-tick-container {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 4px;
  display: inline-block;
  text-align: center;
}
.slider.slider-horizontal .tooltip {
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.slider.slider-horizontal.slider-rtl .slider-track {
  left: initial;
  right: 0;
}
.slider.slider-horizontal.slider-rtl .slider-tick,
.slider.slider-horizontal.slider-rtl .slider-handle {
  margin-left: initial;
  margin-right: -10px;
}
.slider.slider-horizontal.slider-rtl .slider-tick-container {
  left: initial;
  right: 0;
}
.slider.slider-horizontal.slider-rtl .tooltip {
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.slider.slider-disabled .slider-handle {
  background-color: #ccc;
}

.slider input {
  display: none;
}
.slider .tooltip.top {
  margin-top: -18px;
}
.slider .tooltip-inner {
  white-space: nowrap;
  max-width: none;
  border-radius: 4px;
}
.slider .hide {
  display: none;
}
.slider-track {
  position: absolute;
  cursor: pointer;
  background: rgba($color: #000000, $alpha: 0.42);
  border-radius: 4px;
}
.slider-selection {
  position: absolute;
  background: #1d70b8;
  border-radius: 4px;
}
.slider-selection.tick-slider-selection {
  background-image: -webkit-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: -o-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: linear-gradient(to bottom, #8ac1ef 0%, #82b3de 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ac1ef', endColorstr='#ff82b3de', GradientType=0);
}
.slider-track-low,
.slider-track-high {
  position: absolute;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.slider-handle {
  position: absolute;
  top: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #1d70b8;
  background-color: #1d70b8;
}
.slider-handle:hover {
  cursor: pointer;
}
.slider-handle.round {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 1;
  line-break: auto;
  font-weight: normal;
  height: 28px;
  // width: 34px;
  padding: 0 6px;
  margin-left: 6px;
  color:#0b0c0c;
  text-align: center;
  border: 2px solid black;
  background-color: white;
  overflow-x: hidden;
}
.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.tooltip.top {
  // padding: -150px 0;
  // margin-top: -3px;
}
.tooltip.right {
  // padding: 0 5px;
  // margin-left: 3px;
}
.tooltip.bottom {
  // padding: 5px 0;
  // margin-top: 3px;
}
.tooltip.left {
  // padding: 0 5px;
  // margin-left: -3px;
}
div.tooltip-inner {
  background-color: transparent;
}
div.tooltip.tooltip-max.bottom {
  background-color: transparent;
}
div.tooltip.tooltip-min.top,
div.tooltip.tooltip-max.top {
  display: none;
}
.slider.slider-horizontal .slider-track {
  height: 5px;
}
.slider.slider-horizontal {
  height: 70px;
}
.slider-handle:focus,
.slider-handle:active {
  cursor: pointer;
  background-color: black;
  border: 2px solid black;
  box-shadow: 0 0 0 4px $yellow;
}
div.slider.slider-horizontal {
  margin-left: 20px !important;
  margin-top: 10!important;
  margin-bottom: 0!important;
}




// mozilla css (VC-13130)

// @-moz-document url-prefix(){

//   .slider-handle:focus-within {
//     background-color: black;
//     border: 2px solid black;    box-shadow: 0 0 0 4px yellow;
// }
// }